import React from 'react';
import SkeletonBox from '../../generic/loading/SkeletonBox';

const MapSingleLocationLoader = () => {
  return (
    <div className="w-full h-full">
      <div className="flex space-y-4 flex-col">
        <SkeletonBox className="rounded-3xl w-1/3 h-10" />
        <div className="space-y-2">
          <SkeletonBox className="h-60 w-full rounded-3xl" />
          <SkeletonBox className="h-6 w-1/2 rounded-3xl mb-6" />
        </div>
        <SkeletonBox className="h-96 w-full rounded-3xl" />
      </div>
    </div>
  );
};

export default MapSingleLocationLoader;
