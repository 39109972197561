import { useMutation } from 'react-query';
import actions from '../api/locations';
import { useSetRecoilState } from 'recoil';
import { locationsQueryResult } from '../state/location';

const useMutateFetchLocations = () => {
  const setLocationResult = useSetRecoilState(locationsQueryResult);
  return useMutation(
    'useMutateFetchLocations',
    async ({ page, pageSize, locationType, municipality, locationIds }) => {
      const data = await actions.getLocations(
        page,
        pageSize,
        locationType,
        municipality,
        locationIds
      );
      setLocationResult(data);
      return data;
    },
    { enabled: false }
  );
};

export default useMutateFetchLocations;
