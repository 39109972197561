import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useMutateFetchPage from '../hooks/useFetchPage';
import setTitle from '../util/setTitle';
import icon from '../config/icons.js';
import getIcon from '../util/getIcon.js';
import ContactModal from '../components/LocationDetailsPage/ContactModal';
import MetaTags from '../components/generic/MetaTags.js';
import { htmlToText } from 'html-to-text';
import logo from '../assets/iconBeta.svg';

const GenericPage = () => {
  const { pageSlug } = useParams();
  const { data, mutate } = useMutateFetchPage(pageSlug);
  const [isContactModal, setContactModal] = useState(false);
  const description = htmlToText(data?.body, {
    wordwrap: 130,
    ignoreImage: true,
    ignoreHref: true,
  });
  const meta_description = description.slice(0, 150);
  useEffect(() => {
    mutate(pageSlug);
    window.scrollTo(0, 0);
  }, [pageSlug]);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      const headers = document.querySelectorAll('.accordion-header');
      headers.forEach((header) => {
        const button = document.createElement('span');
        button.className = 'button';
        button.textContent = 'Se svar +';
        header.appendChild(button);

        header.addEventListener('click', () => {
          const body = header.nextElementSibling;
          body.classList.toggle('accordion-active');
        });
      });
    }
  }, [data]);

  if (!data) {
    return <>Läser in...</>;
  }

  return (
    <>
      <MetaTags
        title={'Tillgänglighetskartan: ' + data?.title}
        description={meta_description}
        image={data?.image ? data.image : ''}
      />
      <div
        className="
      w-full min-h-[calc(80vh-145px)] mx-auto
      sm:mt-[70px]
      bg-secondary
      flex flex-col md:flex-row
      "
      >
        <Link className="sm:hidden bg-accent w-full" to="/">
          <img alt="logotyp" className="w-72 px-md py-md" src={logo} />
        </Link>
        <div className="flex flex-col md:flex-row items-stretch mx-auto">
          <div className={`flex ${data.image ? 'md:w-2/3' : 'w-full'}`}>
            <div className="md:mx-12 mb-8 my-auto w-full max-h-2/3 space-y-4">
              <div
                className="genericContent"
                dangerouslySetInnerHTML={{ __html: data?.body }}
              />
              <button
                className="items-center mx-auto flex space-x-2 underline"
                onClick={() => setContactModal(true)}
              >
                {getIcon(icon.EMAIL)}
                <p>Kontakta oss</p>
              </button>
            </div>
          </div>
          <ContactModal
            onClose={() => setContactModal(false)}
            isOpen={isContactModal}
          />
          {data?.image && (
            <div className="w-full md:w-1/3 flex mt-4 md:mt-0 md:mr-12">
              <img
                className="w-full object-fit rounded-2xl"
                src={data.image}
                alt="sidobild"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GenericPage;
