import client from './client';

const BASE_URL = '/counties/';

const getCounties = () => {
  const res = client.get(BASE_URL);
  return res;
};

const actions = {
  getCounties,
};

export default actions;
