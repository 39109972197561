import React from 'react';
import propTypes from 'prop-types';
import CloseButton from '../generic/buttons/CloseButton';
import getIcon from '../../util/getIcon';
import icon from '../../config/icons';

const InputField = ({
  placeholder,
  wrapperClassName,
  className,
  text,
  setText,
  onFocus,
  inputRef,
  isReadOnly,
}) => {
  return (
    <div
      className={`${wrapperClassName || 'h-10'} relative w-full rounded-full`}
    >
      {getIcon(
        icon.SEARCH,
        'transform -translate-y-1/2 top-1/2 absolute left-4 text-text2'
      )}
      <input
        ref={inputRef}
        onFocus={onFocus}
        placeholder={placeholder}
        readOnly={isReadOnly}
        className={`
      bg-secondary text-text1 size-full
      pl-10 py-2 px-3 rounded-full
      ${className}`}
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      {text?.length > 0 && (
        <CloseButton
          onClick={() => setText('')}
          buttonStyle="transform -translate-y-1/2 top-1/2 absolute right-4"
          ariaLabel="Rensa sökning"
        />
      )}
    </div>
  );
};

InputField.propTypes = {
  placeholder: propTypes.string,
  wrapperClassName: propTypes.string,
  className: propTypes.string,
  text: propTypes.string,
  setText: propTypes.func,
  onFocus: propTypes.func,
};

export default InputField;
