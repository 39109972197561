import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MobileFilterSearchWrapper from './MobileFilterSearchWrapper';
import RegionCheckBoxTree from '../../middle/dropdown/RegionCheckBoxTree';
import {
  counties,
  locationTree as LocationTreeState,
  options,
} from '../../../../../state/filter';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import RegionPickerAll from '../../middle/dropdown/RegionPickerAll';

const MobileFilterSearchLocation = ({ onSubmit }) => {
  const [locationTree, setLocationTree] = useRecoilState(LocationTreeState);
  const resetState = useResetRecoilState(LocationTreeState);
  const currentOptions = useRecoilValue(options);
  const allCounties = useRecoilValue(counties);
  const [search, setSearch] = useState('');

  const handleClearFilter = () => {
    resetState();
  };

  return (
    <MobileFilterSearchWrapper
      searchPlaceholder="Sök på område..."
      search={search}
      setSearch={setSearch}
      onSubmit={() => onSubmit(locationTree.checked)}
    >
      <div className="w-full py-2">
        <RegionPickerAll
          isChecked={locationTree.checked.length === 0}
          totalCount={currentOptions.location_ids?.length}
          onClick={handleClearFilter}
        />
      </div>
      <RegionCheckBoxTree
        availableOptions={currentOptions.municipalities}
        allOptions={allCounties}
        state={locationTree}
        setState={setLocationTree}
        setSearch={setSearch}
        search={search}
      />
    </MobileFilterSearchWrapper>
  );
};

MobileFilterSearchLocation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default MobileFilterSearchLocation;
