import { useMutation } from 'react-query';
import actions from '../api/locations';

const useMutateFetchSearchOptions = (searchQuery) => {
  return useMutation(
    'useMutateFetchSearchOptions',
    async () => {
      const res = await actions.getSearchOptions(searchQuery);
      return res;
    },
    { enabled: false }
  );
};

export default useMutateFetchSearchOptions;
