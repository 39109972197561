import React from 'react';
import PropTypes from 'prop-types';
import getTagIcon from '../../../../../util/getTagIcon';
import tagType from '../../../../../config/tagType';
import MobileFilterTag from '../MobileFilterTag';
import MobileFilterWrapper from '../MobileFilterWrapper';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  locationTypes,
  selectedLocationTypes,
} from '../../../../../state/filter';

const MobileFilterLocationType = ({
  title,
  description,
  onSubmit,
  isActive,
  setActive,
}) => {
  const [selected, setSelected] = useRecoilState(selectedLocationTypes);
  const allLocationTypes = useRecoilValue(locationTypes);

  const handleClearFilter = () => {
    setSelected([]);
    onSubmit([]);
  };

  return (
    <MobileFilterWrapper
      title={title}
      description={description}
      isActive={isActive}
      setActive={setActive}
      onSubmit={() => onSubmit(selected)}
      onClearFilter={handleClearFilter}
      isFiltered={selected.length > 0}
      selectedComponents={
        <div className="flex flex-wrap">
          {selected.length == 0 && <MobileFilterTag label="Alla platser" />}
          {allLocationTypes
            ?.filter((t) => selected?.includes(t.id))
            .map((t, idx) => (
              <MobileFilterTag
                key={idx}
                style="mr-2 mb-2"
                label={t.label}
                icon={getTagIcon(t.name, tagType.LOCATION_TYPE, '24')}
              />
            ))}
        </div>
      }
    />
  );
};

MobileFilterLocationType.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
};

export default MobileFilterLocationType;
