import React from 'react';
import PropTypes from 'prop-types';

const SkeletonBox = ({ className }) => {
  return (
    <div className={`bg-gray-100 overflow-hidden ${className}`}>
      <div className="w-full h-full skeleton-loading"></div>
    </div>
  );
};

SkeletonBox.propTypes = {
  className: PropTypes.string.isRequired,
};

export default SkeletonBox;
