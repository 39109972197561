import React from 'react';
import ReviewCard from './ReviewCard';

const ReviewList = ({ reviews }) => {
  return (
    <div className="review-list space-y-4 max-[50vh] overflow-y-auto">
      {reviews.map((review, index) => (
        <ReviewCard
          key={index}
          name={review.sender_name}
          timestamp={review.timestamp}
          rating={review.rating}
          description={review.description}
          title={review.title}
          image={review.image}
        />
      ))}
    </div>
  );
};

export default ReviewList;
