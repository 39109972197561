import { atom } from 'recoil';

export const options = atom({
  key: 'options',
  default: {
    municipalities: [],
    location_types: [],
    accessibility_tags: [],
    location_ids: [],
  },
});

export const search = atom({
  key: 'search',
  default: '',
});

export const headerState = atom({
  key: 'headerState',
  default: false,
});

export const activeDropdown = atom({
  key: 'activeDropdown',
  default: -1,
});

export const counties = atom({
  key: 'counties',
  default: [],
});

export const locationTypes = atom({
  key: 'locationTypes',
  default: [],
});

export const accessibilityTags = atom({
  key: 'accessibilityTags',
  default: [],
});

export const locationTree = atom({
  key: 'locationTree',
  default: {
    checked: [],
    parentChecked: [],
    expanded: [],
  },
});

export const selectedLocationTypes = atom({
  key: 'selectedLocationTypes',
  default: [],
});

export const selectedAccessibilityTypes = atom({
  key: 'selectedAccessibilityTypes',
  default: [],
});
