import propTypes from 'prop-types';
import React from 'react';
import getTagIcon from '../../../../util/getTagIcon';
import tagType from '../../../../config/tagType';

const LocationTypeButton = ({
  name,
  label,
  onClick,
  isActive,
  is_disabled,
}) => {
  return (
    <button
      className={`h-full w-full flex items-center justify-center select-none group hover:cursor-pointer 
      ${is_disabled ? 'opacity-20' : isActive ? 'opacity-100' : 'opacity-70'}`}
      onClick={onClick}
      disabled={is_disabled}
      label={label}
    >
      <div
        className={`flex md:flex-col justify-between items-center h-full
                shrink-0 relative text-text1 animate-fadeIn
                duration-filter transition-all text-primary
           `}
      >
        <div className="h-full flex md:flex-col px-4 space-x-2 md:space-x-0 items-center justify-center">
          {getTagIcon(name, tagType.LOCATION_TYPE)}
          <label className="w-full text-md md:text-xs md:font-semibold">
            {label}
          </label>
        </div>
        <div
          className={`${isActive ? 'bg-primary' : 'group-hover:bg-primary/60'}
                absolute bottom-0 w-3/4 transition -translate-x-1/2 left-1/2 h-[3px]
                transition-all ease-in-out`}
        />
      </div>
    </button>
  );
};

LocationTypeButton.propTypes = {
  id: propTypes.number,
  name: propTypes.string,
  label: propTypes.string,
  onClick: propTypes.func,
  isActive: propTypes.bool,
  is_disabled: propTypes.bool,
};

export default LocationTypeButton;
