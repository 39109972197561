import { useQuery } from 'react-query';
import actions from '../api/locations';

const useFetchRelatedLocations = (locationId) => {
  return useQuery('useFetchRelatedLocations', async () => {
    const data = await actions.getRelatedLocations(locationId);
    return data;
  });
};

export default useFetchRelatedLocations;
