import client from './client';

const BASE_URL = 'locations';

const getOptions = async (
  municipality_ids,
  location_type_ids,
  accessibility_tag_ids
) => {
  let url = `${BASE_URL}/options?`;

  municipality_ids?.map((id) => {
    url += `municipality_id=${id}&`;
  });

  location_type_ids?.map((id) => {
    url += `location_type_id=${id}&`;
  });

  //todo: change to 'accessibility_type_id' in backend & frontend
  accessibility_tag_ids?.map((id) => {
    url += `accessibility_tag_id=${id}&`;
  });

  const res = await client.get(url);
  return res.data;
};

const getSearchOptions = async (searchQuery) => {
  const res = await client.get(`${BASE_URL}/search-query?query=${searchQuery}`);
  return res.data;
};

const getLocations = async (
  page,
  pageSize,
  locationType,
  municipality,
  locationIds
) => {
  let url = `${BASE_URL}`;

  url += `?page=${page}&page_size=${pageSize}`;

  if (locationType) {
    url += `&location_type=${locationType}`;
  }

  if (municipality) {
    url += `&municipality=${municipality}`;
  }

  if (locationIds) {
    url += locationIds.map((id) => `&location_id=${id}`).join('');
  }

  const res = await client.get(url);
  return res.data;
};

const getLocation = async (id) => {
  const res = await client.get(`${BASE_URL}/${id}`);
  return res.data;
};

const getRelatedLocations = async (locationId) => {
  const res = await client.get(`${BASE_URL}/related/${locationId}`);
  return res.data;
};

const getRecommendedLocations = async () => {
  const res = await client.get(`${BASE_URL}/recommended`);
  return res.data;
};

const getAccessibilityTags = async () => {
  const res = await client.get(`${BASE_URL}/accessibility-tags`);
  return res.data;
};

const getLocationTypes = async () => {
  const res = await client.get(`${BASE_URL}/location-types`);
  return res.data;
};

const actions = {
  getOptions,
  getSearchOptions,
  getLocations,
  getLocation,
  getAccessibilityTags,
  getLocationTypes,
  getRelatedLocations,
  getRecommendedLocations,
};

export default actions;
