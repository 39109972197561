import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import InputField from '../../../InputField';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import {
  locationTypes,
  selectedLocationTypes,
} from '../../../../../state/filter';
import getTagIcon from '../../../../../util/getTagIcon';
import tagType from '../../../../../config/tagType';
import SubmitButton from '../../../buttons/SubmitButton';
import FilterTypePickerButton from './FilterTypePickerButton';
import ClearFilterButton from '../../../buttons/ClearFilterButton';

const LocationTypePicker = ({ availableOptions, onSubmit }) => {
  const [search, setSearch] = useState('');
  const allLocationTypes = useRecoilValue(locationTypes);
  const [selected, setSelected] = useRecoilState(selectedLocationTypes);
  const resetSelected = useResetRecoilState(selectedLocationTypes);

  const handleReset = () => {
    resetSelected();
    onSubmit(null);
  };

  useEffect(() => {
    setSelected((prevSelected) =>
      prevSelected.filter((id) =>
        availableOptions.some((option) => option.id === id)
      )
    );
  }, [availableOptions, setSelected]);

  const filteredLocationTypes = allLocationTypes
    .filter((t) => t.label.toLowerCase().startsWith(search.toLowerCase()))
    .sort((a, b) => {
      const aInData = availableOptions?.find((o) => o.id == a.id)?.count || -1;
      const bInData = availableOptions?.find((o) => o.id == b.id)?.count || -1;
      return aInData < bInData;
    });

  const onSelect = (id) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return (
    <div className="flex flex-col space-y-2 h-full select-none">
      <div className="flex justify-between bg-accent rounded-full p-2 space-x-2">
        <InputField
          placeholder="Sök på plats"
          text={search}
          setText={setSearch}
        />
        <SubmitButton
          label={
            selected.length > 0 ? `Spara val (${selected.length})` : 'Nästa'
          }
          className="w-2/5"
          onClick={() => onSubmit(selected)}
        />
      </div>
      <div className="overflow-y-scroll h-full w-full dropdown-grid">
        {filteredLocationTypes.map((type, idx) => {
          const locationType = availableOptions?.find((e) => e.id == type.id);
          return (
            <FilterTypePickerButton
              label={`${type.label} ${locationType ? `(${locationType.count})` : ''}`}
              isDisabled={!locationType}
              key={idx}
              onClick={() => onSelect(type.id)}
              isActive={selected.includes(type.id)}
              icon={getTagIcon(type.name, tagType.LOCATION_TYPE)}
            />
          );
        })}
      </div>
      <div className="pt-2">
        {selected.length > 0 && (
          <ClearFilterButton
            onClick={handleReset}
            buttonStyle="w-fit ml-auto"
          />
        )}
      </div>
    </div>
  );
};

LocationTypePicker.propTypes = {
  availableOptions: propTypes.array,
  onSubmit: propTypes.func,
};

export default LocationTypePicker;
