import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ className }) => {
  if (className) {
    return (
      <div
        className={`${className} 
        transform -translate-x-1/2 left-1/2 p-1 opacity-80
        absolute top-2 z-40
         `}
      >
        <div
          className="
            h-6 w-6 animate-spin rounded-full border-b-2 border-primary
            "
        ></div>
      </div>
    );
  }
  return (
    <div className="h-6 w-6 animate-spin rounded-full border-b-2 border-primary" />
  );
};

Loader.propTypes = {
  className: PropTypes.string,
};

export default Loader;
