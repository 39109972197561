import React from 'react';
import propTypes from 'prop-types';
import getIcon from '../../../util/getIcon';
import { Link, useNavigate } from 'react-router-dom';

const IconLinkButton = ({
  text,
  icon,
  buttonStyle,
  iconStyle,
  textStyle,
  hideTextOnMobile = false,
  isDark = true,
  href,
  target,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (target !== '_blank') {
      navigate(href);
    }
  };

  return (
    <Link
      to={href}
      onClick={handleClick}
      target={target}
      className={`
        h-10 flex items-center space-x-2 rounded-full dont-print-this
        ${isDark ? 'bg-primary text-secondary border-transparent' : 'bg-secondary text-primary border-border'}
        ${isDark ? 'hover:bg-secondary hover:text-primary hover:border-primary' : 'hover:bg-primary hover:text-secondary hover:border-transparent'}
        transition-all text-md border
         ${text ? 'rounded-full p-4' : 'rounded-md p-3'}
         ${buttonStyle}
         space-x-2
         `}
    >
      {getIcon(icon, iconStyle)}
      {text && (
        <p
          className={`whitespace-nowrap ${hideTextOnMobile ? 'hidden md:block' : ''}
        ${textStyle ? textStyle : ''}
        ${icon ? '' : 'w-full'}`}
        >
          {text}
        </p>
      )}
    </Link>
  );
};

IconLinkButton.propTypes = {
  text: propTypes.string,
  icon: propTypes.string,
  buttonStyle: propTypes.string,
  iconStyle: propTypes.string,
  textStyle: propTypes.string,
  hideTextOnMobile: propTypes.bool,
  isDark: propTypes.bool,
  href: propTypes.string.isRequired,
};

export default IconLinkButton;
