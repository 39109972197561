import client from './client';

const BASE_URL = 'contacts';

const postContact = async (contactData) => {
  try {
    const res = await client.post(`${BASE_URL}/create/`, contactData);
    return res.data;
  } catch (error) {
    throw new Error('Failed to post contacts. Please try again.');
  }
};

const actions = {
  postContact,
};

export default actions;
