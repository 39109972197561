import React from 'react';
import MobileFilterWrapper from '../MobileFilterWrapper';
import PropTypes from 'prop-types';
import getTagIcon from '../../../../../util/getTagIcon';
import tagType from '../../../../../config/tagType';
import MobileFilterTag from '../MobileFilterTag';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  accessibilityTags,
  selectedAccessibilityTypes,
} from '../../../../../state/filter';

const MobileFilterAccessibility = ({
  title,
  description,
  onSubmit,
  isActive,
  setActive,
}) => {
  const [selected, setSelected] = useRecoilState(selectedAccessibilityTypes);
  const allAccessibilityTags = useRecoilValue(accessibilityTags);

  const handleClearFilter = () => {
    setSelected([]);
    onSubmit([]);
  };

  return (
    <MobileFilterWrapper
      title={title}
      description={description}
      isActive={isActive}
      setActive={setActive}
      onClearFilter={handleClearFilter}
      isFiltered={selected.length > 0}
      selectedComponents={
        <div className="flex flex-wrap">
          {selected.length === 0 && (
            <MobileFilterTag label="Inget specificerat" />
          )}
          {allAccessibilityTags
            .flatMap((category) => category.tags)
            .filter((tag) => selected.includes(tag.id))
            .map((tag, idx) => (
              <MobileFilterTag
                style="mr-2 mb-2"
                key={idx}
                label={tag.label}
                icon={getTagIcon(tag.name, tagType.ACCESSIBILITY, '24')}
              />
            ))}
        </div>
      }
      onSubmit={() => onSubmit(selected)}
      submitLabel="Hitta platser"
    />
  );
};

MobileFilterAccessibility.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MobileFilterAccessibility;
