import { atom } from 'recoil';

export const activeLocationType = atom({
  key: 'activeLocationType',
  default: null,
});

export const locationsQueryResult = atom({
  key: 'locationsQueryResult',
  default: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
});

export const locations = atom({
  key: 'locations',
  default: [],
});

export const favoritesLocationIds = atom({
  key: 'favoriteLocationIds',
  default: [],
});
