import client from './client';

const BASE_URL = 'pages';

const getPages = async () => {
  const res = await client.get(`${BASE_URL}/`);
  return res.data;
};

const getPage = async (slug) => {
  const res = await client.get(`${BASE_URL}/${slug}`);
  return res.data;
};

const actions = {
  getPages,
  getPage,
};

export default actions;
