import React from 'react';
import SkeletonBox from '../../../generic/loading/SkeletonBox';

const MapLocationListItemLoader = () => {
  return (
    <div className="h-32 rounded-lg flex w-full p-4 space-x-2 border bg-secondary">
      <div className="h-full">
        <SkeletonBox className="size-20 rounded-lg" />
      </div>
      <div className="flex flex-col h-full w-full space-y-2 justify-center">
        <SkeletonBox className="h-4 w-24 rounded-md" />
        <SkeletonBox className="h-4 w-20 rounded-md" />
      </div>
    </div>
  );
};

export default MapLocationListItemLoader;
