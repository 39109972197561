import React from 'react';
import PropTypes from 'prop-types';

const MobileFilterTag = ({ label, icon, style }) => {
  return (
    <div
      className={`px-3 py-1 border border-border rounded-full flex justify-center items-center
        ${icon ? 'space-x-2' : ''}
        max-w-64 s pace-x-2 text-primary px-2 ${style}`}
    >
      {icon}
      <label className="font-medium text-sm text-center capitalize">
        {label}
      </label>
    </div>
  );
};

MobileFilterTag.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.any,
};

export default MobileFilterTag;
