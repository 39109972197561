import React from 'react';
import PropTypes from 'prop-types';
import LocationDetailsGalleryImageButton from './LocationDetailsGalleryImageButton';
import LocationGallerySwiper from '../../generic/LocationGallerySwiper';

const LocationDetailsGallery = ({ images, mainImage, onImageClick }) => {
  return (
    <div className="h-full">
      {
        <div className="flex sm:hidden h-full">
          <LocationGallerySwiper images={images} onImageClick={onImageClick} />
        </div>
      }
      <div className="flex-wrap relative hidden sm:flex h-full">
        {images.length === 0 && (
          <div className="w-full h-full">
            <img
              src={mainImage}
              alt="Omslagsbild"
              className="w-full h-full rounded-2xl object-cover"
            />
          </div>
        )}
        {images.length === 1 && (
          <LocationDetailsGalleryImageButton
            width="w-full"
            url={images[0].image}
            alt={images[0].description}
            onClick={() => onImageClick(0)}
          />
        )}
        {images.length === 2 && (
          <div className="flex w-full space-x-3">
            <LocationDetailsGalleryImageButton
              width="w-1/2"
              url={images[0].image}
              alt={images[0].description}
              onClick={() => onImageClick(0)}
            />
            <LocationDetailsGalleryImageButton
              width="w-1/2"
              url={images[1].image}
              alt={images[1].description}
              onClick={() => onImageClick(1)}
            />
          </div>
        )}
        {images.length >= 3 && (
          <div className="flex flex-col w-full h-full">
            <LocationDetailsGalleryImageButton
              style="pb-3"
              height="h-1/2"
              url={images[0].image}
              alt={images[0].description}
              onClick={() => onImageClick(0)}
            />
            <div className="flex w-full space-x-3 h-1/2">
              <LocationDetailsGalleryImageButton
                height="w-1/2"
                url={images[1].image}
                alt={images[1].description}
                onClick={() => onImageClick(1)}
              />
              <LocationDetailsGalleryImageButton
                height="w-1/2"
                url={images[2].image}
                alt={images[2].description}
                onClick={() => onImageClick(2)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

LocationDetailsGallery.propTypes = {
  images: PropTypes.array.isRequired,
  mainImage: PropTypes.string.isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default LocationDetailsGallery;
