import { useMutation } from 'react-query';
import actions from '../api/pages';

const useMutateFetchPage = (slug) => {
  return useMutation(
    'useMutateFetchPage',
    async () => {
      const data = await actions.getPage(slug);
      return data;
    },
    { enabled: false }
  );
};

export default useMutateFetchPage;
