import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { options } from '../../../../state/filter';
import icon from '../../../../config/icons';
import useMutateFetchOptions from '../../../../hooks/useMutateFetchOptions';
import useMutateFetchSearchOptions from '../../../../hooks/useMutateFetchSearchOptions';
import MobileFilterLocationType from './locationType/MobileFilterLocationType';
import MobileFilterSearchAccessibilityTypes from './search/MobileFilterSearchAccessibilityTypes';
import MobileFilterAccessibility from './accessibility/MobileFilterAccessibility';
import MobileFilterLocation from './location/MobileFilterLocation';
import MobileFilterBar from './MobileFilterBar';
import getIcon from '../../../../util/getIcon';
import IconButton from '../../buttons/IconButton';
import MobileFilterSearchLocation from './search/MobileFilterSearchLocation';
import MobileFilterSearchLocationType from './search/MobileFilterSearchLocationType';
import Search from './Search';
import InputField from '../../InputField';

import 'swiper/css';

const FILTER_STATE = {
  LOCATION: 0,
  TYPE: 1,
  ACCESSIBILITY: 2,
};

const filterComponents = [
  {
    component: MobileFilterLocation,
    searchComponent: MobileFilterSearchLocation,
    title: 'Plats',
    description: 'Välj en eller flera platser du vill se',
    optionKey: 'municipality_ids',
    optionSelector: (options) => options.municipalities,
    dataSelector: (state) => state.counties,
    cardStep: FILTER_STATE.LOCATION,
  },
  {
    component: MobileFilterLocationType,
    searchComponent: MobileFilterSearchLocationType,
    title: 'Typ av plats',
    description: 'Välj en eller flera kategorier du vill se platser på',
    optionKey: 'location_type_ids',
    optionSelector: (options) => options.location_types,
    dataSelector: (state) => state.locationTypes,
    cardStep: FILTER_STATE.TYPE,
  },
  {
    component: MobileFilterAccessibility,
    searchComponent: MobileFilterSearchAccessibilityTypes,
    title: 'Tillgänglighet',
    description: 'Välj en eller flera kategorier inom tillgänglighet',
    optionKey: 'accessibility_tag_ids',
    optionSelector: (options) => options.accessibility_tags,
    dataSelector: (state) => state.accessibilityTags,
    cardStep: FILTER_STATE.ACCESSIBILITY,
  },
];

const MobileFilterModal = ({ isOpen, setOpen, setLocationIds }) => {
  const modalContentRef = useRef();
  const [currentCard, setCurrentCard] = useState(-1);
  const [searchText, setSearchText] = useState('');
  const searchInputRef = useRef();

  const swiper = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);

  const [outputOptions, setOutputOptions] = useRecoilState(options);
  const [inputOptions, setInputOptions] = useState({});

  const [startY, setStartY] = useState(0);
  const [position, setPosition] = useState(0);

  const {
    data: searchResults,
    mutate: mutateSearch,
    isLoading: isSearchLoading,
  } = useMutateFetchSearchOptions(searchText);

  useEffect(() => {
    if (searchText) {
      mutateSearch();
    }
  }, [mutateSearch, searchText]);

  const {
    mutate: mutateFetchOptions,
    isSuccess: isOptionsSuccess,
    data: optionsData,
    isLoading,
  } = useMutateFetchOptions(inputOptions);

  const onSearch = useCallback(() => {
    setLocationIds(outputOptions.location_ids);
    setOpen(false);
  }, [setLocationIds, outputOptions.location_ids]);

  const onSlide = (data) => {
    setSlideIndex(data.activeIndex);
  };

  const handleBarClick = () => {
    if (slideIndex === 2) {
      setCurrentCard(-1);
      slideTo(1);
    }
  };

  const onSubmitInputOptions = useCallback((key, data) => {
    setInputOptions((prevState) => ({
      ...prevState,
      [key]: data,
    }));
    setCurrentCard(-1);
    slideTo(1);
  }, []);

  useEffect(() => {
    if (Object.keys(inputOptions).length > 0) {
      mutateFetchOptions();
    }
  }, [inputOptions]);

  useEffect(() => {
    if (isOptionsSuccess && optionsData !== outputOptions) {
      setOutputOptions(optionsData);
    }
  }, [isOptionsSuccess, optionsData, setOutputOptions, outputOptions]);

  useEffect(() => {
    setCurrentCard(-1);
    setTimeout(() => {
      slideTo(0);
      searchInputRef?.current?.focus();
    }, 500);
  }, [isOpen]);

  const slideTo = (index) => {
    swiper?.current?.swiper.slideTo(index);
  };

  const handleCardClick = (cardIndex) => {
    setCurrentCard(currentCard === cardIndex ? -1 : cardIndex);
    slideTo(2);
  };

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    const currentY = e.touches[0].clientY;
    const diff = currentY - startY;

    if (diff > 0) {
      setPosition(diff);
    }
  };

  const handleTouchEnd = () => {
    if (position > 100) {
      setOpen(false);
    }
    setPosition(0);
  };

  const getSearchText = () => {
    const length = outputOptions?.location_ids.length;
    if (length === 0) {
      return 'Inga resultat';
    } else if (length === 1) {
      return 'Visa 1 resultat';
    } else {
      return `Visa alla ${length} resultat`;
    }
  };

  return (
    <div
      className={`${isOpen ? 'h-[95%] max-h-[100%]' : 'max-h-0'} 
        transition-all duration-300 ease-in-out
        fixed bottom-0 left-0 z-20 w-full select-none`}
      style={{ transform: `translateY(${position}px)` }}
    >
      <div className="px-6 size-full flex flex-col mobile-filter-modal pb-10 bg-secondary rounded-t-[40px] overflow-hidden relative">
        <MobileFilterBar
          onClick={handleBarClick}
          onClose={() => setOpen(false)}
          buttonIcon={getIcon(
            icon.ARROW,
            `-rotate-90 text-xl ${slideIndex === 2 ? 'opacity-100' : 'opacity-0'}`
          )}
          title={
            slideIndex === 0
              ? 'Sök'
              : slideIndex === 1
                ? 'Alla filter'
                : filterComponents[currentCard]?.title
          }
          isLoading={isLoading || isSearchLoading}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        />
        <div
          className={`flex w-full h-14 min-h-14 mb-2 space-x-2 ${slideIndex === 2 ? 'hidden' : ''}`}
        >
          <InputField
            wrapperClassName="size-full"
            inputRef={searchInputRef}
            className={`outline-none border border-border ${slideIndex === 0 ? 'border-primary' : ''}`}
            placeholder="Vad vill du söka efter?"
            text={searchText}
            setText={setSearchText}
            onFocus={() => slideTo(0)}
          />
          <button
            onClick={() => slideTo(1)}
            className={`h-14 px-4 h-full border border-border rounded-full shadow-2xl text-sm text-text2 -primary bg-secondary flex items-center justify-center 
              ${slideIndex === 1 ? 'border-primary' : ''}`}
          >
            <div className="flex space-x-1 items-center">
              {getIcon(icon.FILTER, 'text-lg')}
              <span>Filter</span>
            </div>
          </button>
        </div>
        <Swiper
          ref={swiper}
          className="size-full"
          onSlideChange={onSlide}
          allowTouchMove={false}
        >
          <SwiperSlide>
            <Search
              onClose={() => setOpen(false)}
              searchResults={searchResults}
              isSearchLoading={isSearchLoading}
              text={searchText}
            />
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-full flex flex-col">
              <div
                className="w-full h-fit overflow-y-scroll divide-y"
                ref={modalContentRef}
              >
                {filterComponents.map(
                  ({
                    component: Component,
                    title,
                    description,
                    optionKey,
                    optionSelector,
                    dataSelector,
                    cardStep,
                  }) => (
                    <Component
                      key={cardStep}
                      title={title}
                      description={description}
                      options={optionSelector(outputOptions)}
                      onSubmit={(ids) => onSubmitInputOptions(optionKey, ids)}
                      isActive={currentCard === cardStep}
                      setActive={() => handleCardClick(cardStep)}
                    />
                  )
                )}
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="size-full">
            {filterComponents
              .filter((el) => currentCard === el.cardStep)
              .map(({ searchComponent: Component, cardStep, optionKey }) => (
                <Component
                  key={cardStep}
                  onSubmit={(data) => onSubmitInputOptions(optionKey, data)}
                />
              ))}
          </SwiperSlide>
        </Swiper>
        {slideIndex === 1 && (
          <IconButton
            text={getSearchText()}
            buttonStyle="w-full mt-auto p-4 h-fit"
            onClick={onSearch}
          />
        )}
      </div>
    </div>
  );
};

MobileFilterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setLocationIds: PropTypes.func.isRequired,
};

export default MobileFilterModal;
