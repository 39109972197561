import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getSavedSearches,
  removeSearch,
  saveSearch,
} from '../../../../util/savedSearches';
import SearchRow from './SearchRow';
import useFetchRecommendedLocaitons from '../../../../hooks/useFetchRecommendedLocations';
import SearchRowRecommended from './SearchRowRecommended';

function Search({ searchResults, onClose, text, isSearchLoading }) {
  const [history, setHistory] = useState([]);
  const { data: recommendedLocations, refetch: refetchRecommendedLocations } =
    useFetchRecommendedLocaitons();

  useEffect(() => {
    setHistory(getSavedSearches());
    refetchRecommendedLocations();
  }, []);

  const handleSubmit = (location) => {
    const ids = history.map((h) => h.id);

    if (!ids.includes(location.id)) {
      saveSearch(location);
      setHistory([...history, location]);
    }

    onClose();
  };

  const handleRemove = (location) => {
    removeSearch(location);
    setHistory([...history.filter((s) => s.id !== location.id)]);
  };

  return (
    <div className="w-full h-full space-y-2 relative overflow-y-auto">
      {text?.length > 0 && !isSearchLoading && (
        <ol className="">
          {searchResults?.length > 0 ? (
            searchResults?.map((locationResult, idx) => (
              <li className="" key={idx}>
                <SearchRow location={locationResult} onClick={handleSubmit} />
              </li>
            ))
          ) : !isSearchLoading ? (
            <label className="text-text2 text-sm">
              Vi hittade inga resultat för
              <span className="text-text1">{` ${text}.`}</span>
            </label>
          ) : (
            <></>
          )}
        </ol>
      )}
      {history.length > 0 && (
        <ol className="pt-4">
          <h3 className="text-xl text-text1 font-medium">Tidigare sökningar</h3>
          {history
            .slice(0, 5)
            .reverse()
            .map((s, idx) => (
              <SearchRow
                key={idx}
                onClick={handleSubmit}
                location={s}
                onClear={handleRemove}
              />
            ))}
        </ol>
      )}
      {text?.length === 0 && recommendedLocations?.length > 0 && (
        <ol className="pt-4">
          <h3 className="text-xl text-text1 font-medium">Populära sökningar</h3>
          {recommendedLocations
            .slice(0, 5)
            .reverse()
            .map((s, idx) => (
              <SearchRowRecommended
                key={idx}
                onClick={handleSubmit}
                location={s}
              />
            ))}
        </ol>
      )}
    </div>
  );
}

Search.propTypes = {
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string,
  isSearchLoading: PropTypes.bool,
  searchResults: PropTypes.array,
};

export default Search;
