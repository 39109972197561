import client from './client';

const BASE_URL = 'reports';

const postReport = async (reportData) => {
  try {
    const res = await client.post(`${BASE_URL}/create/`, reportData);
    return res.data;
  } catch (error) {
    throw new Error('Failed to post reports. Please try again.');
  }
};

const actions = {
  postReport,
};

export default actions;
