import React from 'react';
import ModalCard from '../generic/ModalCard';
import PropTypes from 'prop-types';
import Image360Viewer from '../generic/Image360Viewer';

const LocationDetails360Modal = ({ isOpen, setOpen, image }) => {
  return (
    <ModalCard
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      size="size-full md:rounded-none"
      padding="md:p-0"
    >
      <div className="relative size-full">
        <Image360Viewer src={image} isActive={true} />
      </div>
    </ModalCard>
  );
};

LocationDetails360Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  image: PropTypes.string,
};

export default LocationDetails360Modal;
