import React from 'react';
import PropTypes from 'prop-types';
import getTagIcon from '../../util/getTagIcon';
import tagType from '../../config/tagType';

const LocationTag = ({
  name,
  label,
  ariaLabel,
  onHover,
  onHoverExit,
  className,
  size = '24',
}) => {
  return (
    <button
      className={`
      flex items-center space-x-2 rounded-lg
      shrink-0 py-0.5 px-2
       bg-secondary text-primary
       ${className}
       `}
      onMouseEnter={onHover}
      onMouseLeave={onHoverExit}
      label={label}
      aria-label={ariaLabel}
    >
      {name && getTagIcon(name, tagType.ACCESSIBILITY, size)}
      {label && (
        <label className="text-xs font-semibold text-primary">{label}</label>
      )}
    </button>
  );
};

LocationTag.propTypes = {
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  onHover: PropTypes.func,
  onHoverExit: PropTypes.func,
};

export default LocationTag;
