import React from 'react';
import PropTypes from 'prop-types';
import getIcon from '../../../../util/getIcon';
import icon from '../../../../config/icons';
import Rating from '../../../generic/Rating';

const MapLocationListItem = ({
  id,
  onClick,
  image,
  name,
  municipality,
  locationTypeIcon,
  rating,
  totalReviews,
  has360,
}) => {
  return (
    <li
      key={id}
      onClick={onClick}
      className="cursor-pointer p-4 bg-secondary border border-border flex
      items-center space-x-4 rounded-lg h-32
      relative hover:skew-y-1
      group hover:shadow-sm transition-all"
    >
      <div
        className="transition-all relative
       size-20 flex-shrink-0"
      >
        {image ? (
          <img
            src={image}
            alt={name}
            className="w-full h-full object-cover rounded-lg"
          />
        ) : (
          <div className="flex items-center justify-center h-full text-sm">
            {name}
          </div>
        )}
        <div className="absolute -top-3 -left-3 p-2 rounded-lg bg-secondary">
          {locationTypeIcon}
        </div>
      </div>
      <div className="size-full mb-4 flex flex-col justify-center">
        <div className="flex items-center justify-between">
          <h3 className="font-semibold text-lg">{name}</h3>
        </div>
        <p className="text-sm text-text3">{municipality}</p>
        <div className="hidden group-hover:block absolute transform -translate-y-1/2 top-1/2 right-3 text-2xl text-text3">
          {getIcon(icon.ARROW, 'rotate-90')}
        </div>
      </div>
      <div className="absolute bottom-6 right-4 text-xs text-text1 rounded-md flex items-center space-x-1 order border-border">
        <Rating rating={rating} totalReviews={totalReviews} />
      </div>
      {has360 && (
        <div className="absolute top-6 right-4 space-y-0">
          {getIcon(icon._360)}
        </div>
      )}
    </li>
  );
};

MapLocationListItem.propTypes = {
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  municipality: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  rating: PropTypes.number,
  totalReviews: PropTypes.number,
};

export default MapLocationListItem;
