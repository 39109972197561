import { getLocalStorageItem, setLocalStorageItem } from './localStorageUtil';

const STORAGE_KEY = 'saved_locations';

export const getSavedLocations = () => {
  const saved = getLocalStorageItem(STORAGE_KEY);
  return saved ? JSON.parse(saved) : [];
};

export const saveLocation = (locationId) => {
  const saved = getSavedLocations();
  if (!saved.includes(locationId)) {
    saved.push(locationId);
    setLocalStorageItem(STORAGE_KEY, JSON.stringify(saved));
  }
};

export const removeSavedLocation = (locationId) => {
  let saved = getSavedLocations();
  saved = saved.filter((id) => id !== locationId);
  setLocalStorageItem(STORAGE_KEY, JSON.stringify(saved));
};

export const isSavedLocation = (locationId) => {
  const saved = getSavedLocations();
  return saved.includes(locationId);
};
