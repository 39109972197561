import React from 'react';
import propTypes from 'prop-types';
import getIcon from '../../../../util/getIcon';
import icon from '../../../../config/icons';

const MobileSearchField = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="size-full pb-2 pt-4"
      aria-label="Påbörja sök"
    >
      <div className="py-2 px-6 flex items-center space-x-2 rounded-full mobile-search bg-secondary overflow-hidden w-full">
        {getIcon(icon.SEARCH, 'text-2xl shrink-0')}
        <div className="flex flex-col w-full text-left">
          <h1
            className="text-text1 text-md font-medium"
            aria-label="Vad vill du söka på?"
          >
            Vad vill du göra?
          </h1>
          <h2
            className="text-text3 text-sm truncate font-medium truncate pr-6"
            aria-label="Sök på ställen att åka till eller saker att göra."
          >
            Sök på ställen att åka till, saker att göra, platser att utforska...
          </h2>
        </div>
      </div>
    </button>
  );
};

MobileSearchField.propTypes = {
  onClick: propTypes.func.isRequired,
};

export default MobileSearchField;
