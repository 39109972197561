import React from 'react';
import PropTypes from 'prop-types';

const ClearFilterButton = ({
  onClick,
  buttonStyle,
  ariaLabel = 'Rensa filter',
}) => {
  return (
    <button
      className={`${buttonStyle} px-4 py-1 bg-accent rounded-md text-text1 clear-filter`}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      Rensa
    </button>
  );
};

ClearFilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonStyle: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default ClearFilterButton;
