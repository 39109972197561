import icon from '../config/icons';

import {
  IoCloseSharp,
  IoCopy,
  IoFilterSharp,
  IoHeart,
  IoHeartOutline,
  IoInformation,
  IoMenuSharp,
  IoPrint,
  IoSearchSharp,
  IoStar,
} from 'react-icons/io5';
import {
  AiFillCheckSquare,
  AiFillMinusSquare,
  AiOutlineBorder,
  AiOutlineMinus,
  AiOutlinePlus,
} from 'react-icons/ai';
import { CiImageOn } from 'react-icons/ci';
import { FiFlag, FiMap } from 'react-icons/fi';
import {
  IoIosArrowUp,
  IoIosCheckmark,
  IoMdMail,
  IoLogoWhatsapp,
} from 'react-icons/io';
import { FaFacebook, FaFacebookMessenger } from 'react-icons/fa';
import { MdNavigation, MdOutlineNavigation } from 'react-icons/md';
import { BsTwitterX } from 'react-icons/bs';
import { PiMapPinFill } from 'react-icons/pi';
import { GoShare } from 'react-icons/go';
import { BiDirections } from 'react-icons/bi';
import { TbView360Number } from 'react-icons/tb';

const getIcon = (type, className) => {
  switch (type) {
    case icon.SEARCH:
      return <IoSearchSharp className={className} aria-label={type} />;
    case icon.FILTER:
      return <IoFilterSharp className={className} aria-label={type} />;
    case icon.CHECK:
      return <IoIosCheckmark className={className} aria-label={type} />;
    case icon.ARROW:
      return <IoIosArrowUp className={className} aria-label={type} />;
    case icon.SHARE:
      return <GoShare className={className} aria-label={type} />;
    case icon.PRINT:
      return <IoPrint className={className} aria-label={type} />;
    case icon.COPY:
      return <IoCopy className={className} aria-label={type} />;
    case icon.EMAIL:
      return <IoMdMail className={className} aria-label={type} />;
    case icon.FACEBOOK:
      return <FaFacebook className={className} aria-label={type} />;
    case icon.TWITTER:
      return <BsTwitterX className={className} aria-label={type} />;
    case icon.MESSENGER:
      return <FaFacebookMessenger className={className} aria-label={type} />;
    case icon.WHATSAPP:
      return <IoLogoWhatsapp className={className} aria-label={type} />;
    case icon.HEART:
      return <IoHeartOutline className={className} aria-label={type} />;
    case icon.HEART_FILLED:
      return <IoHeart className={className} aria-label={type} />;
    case icon.CLOSE:
      return <IoCloseSharp className={className} aria-label={type} />;
    case icon.MENU:
      return <IoMenuSharp className={className} aria-label={type} />;
    case icon.CHECKED:
      return <AiFillCheckSquare className={className} aria-label={type} />;
    case icon.UNCHECKED:
      return <AiOutlineBorder className={className} aria-label={type} />;
    case icon.HALF_CHECKED:
      return <AiFillMinusSquare className={className} aria-label={type} />;
    case icon.PIN:
      return <PiMapPinFill className={className} aria-label={type} />;
    case icon.MAP:
      return <FiMap className={className} aria-label={type} />;
    case icon.IMAGE:
      return <CiImageOn className={className} aria-label={type} />;
    case icon._360:
      return <TbView360Number className={className} aria-label={type} />;
    case icon.DIRECTIONS:
      return <BiDirections className={className} aria-label={type} />;
    case icon.PLUS:
      return <AiOutlinePlus className={className} aria-label={type} />;
    case icon.MINUS:
      return <AiOutlineMinus className={className} aria-label={type} />;
    case icon.NAVIGATION:
      return <MdOutlineNavigation className={className} aria-label={type} />;
    case icon.NAVIGATION_FILLED:
      return <MdNavigation className={className} aria-label={type} />;
    case icon.FLAG:
      return <FiFlag className={className} aria-label={type} />;
    case icon.STAR:
      return <IoStar className={className} aria-label={type} />;
    case icon.INFO:
      return <IoInformation className={className} aria-label={type} />;
    default:
      return null;
  }
};

export default getIcon;
