import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import ArrowButton from './buttons/ArrowButton';
import SkeletonBox from '../generic/loading/SkeletonBox';

const LocationGallerySwiper = ({ images, onImageClick }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const onSlide = (data) => {
    setSlideIndex(data.activeIndex);
  };

  return (
    <div className="size-full relative group">
      <div
        className="flex w-full justify-between absolute
        z-[2]
        transition-all
        md:opacity-0 md:group-hover:opacity-100
        px-2
        top-0 left-0
      transform top-1/2 -translate-y-1/2"
      >
        <ArrowButton
          className={`-rotate-90 custom-prev-gallery1 shadow-none
            scale-[0.8] hover:scale-[0.9] bg-secondary/80 text-primary
            ${slideIndex === 0 ? 'opacity-0' : 'opacity-100'}
            `}
        />
        <ArrowButton
          className={`rotate-90 custom-next-gallery1 shadow-none
            scale-[0.8] hover:scale-[0.9] bg-secondary/80 text-primary
            ${slideIndex === images.length - 1 ? 'opacity-0' : 'opacity-100'}`}
        />
      </div>
      <Swiper
        onSlideChange={onSlide}
        pagination={{
          type: 'fraction',
        }}
        className="size-full rounded-2xl"
        modules={[Pagination, Navigation]}
        spaceBetween={0}
        freeMode={true}
        speed={600}
        navigation={{
          prevEl: '.custom-prev-gallery1',
          nextEl: '.custom-next-gallery1',
        }}
      >
        {images.map((image, idx) => (
          <SwiperSlide key={idx}>
            {isLoading && <SkeletonBox className="size-full" />}
            <img
              src={image.image}
              alt={image.description}
              onClick={onImageClick}
              className="size-full object-cover"
              loading="lazy"
              onLoad={() => setLoading(false)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

LocationGallerySwiper.propTypes = {
  images: PropTypes.array.isRequired,
  onImageClick: PropTypes.func,
};

export default LocationGallerySwiper;
