import React from 'react';
import PropTypes from 'prop-types';
import ClearFilterButton from '../../buttons/ClearFilterButton';
import getIcon from '../../../../util/getIcon';
import icon from '../../../../config/icons';

const MobileFilterWrapper = ({
  title,
  description,
  selectedComponents,
  setActive,
  onClearFilter,
  isFiltered,
}) => {
  const handleClick = (event) => {
    const isFilter = event.target.classList.contains('clear-filter');

    if (isFilter) {
      return;
    }

    setActive();
  };

  return (
    <div
      className="relative w-full h-fit flex flex-col justify-between py-2"
      onClick={handleClick}
    >
      <div className="size-full">
        {getIcon(
          icon.ARROW,
          'rotate-90 text-xl absolute right-2 transform top-1/2 -translate-y-1/2'
        )}
        <div className="flex justify-between items-center">
          <h2 className={`font-medium text-xl text-primary`}>{title}</h2>
          {isFiltered && <ClearFilterButton onClick={onClearFilter} />}
        </div>
        <h3 className="text-text2 pr-6">{description}</h3>
        <div className="flex justify-between items-center pt-2 pb-4">
          {selectedComponents}
        </div>
      </div>
    </div>
  );
};

MobileFilterWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  selectedComponents: PropTypes.any.isRequired,
  setActive: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  isFiltered: PropTypes.bool.isRequired,
};

export default MobileFilterWrapper;
