import React from 'react';
import PropTypes from 'prop-types';
import getIcon from '../../../util/getIcon';
import icon from '../../../config/icons';

const CloseButton = ({ onClick, buttonStyle, text, ariaLabel }) => {
  return (
    <button
      onClick={onClick}
      className={`${buttonStyle} ${text ? 'flex items-center justify-between px-3 border border-border py-1' : 'p-1'} rounded-full hover:bg-hover `}
      aria-label={ariaLabel || text}
    >
      {getIcon(icon.CLOSE)}
      {text}
    </button>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonStyle: PropTypes.string,
};

export default CloseButton;
