import { useMutation } from 'react-query';
import actions from '../api/locations';

const useMutateFetchOptions = (data) => {
  return useMutation(
    'useMutateFetchOptions',
    async () => {
      const res = await actions.getOptions(
        data?.municipality_ids,
        data?.location_type_ids,
        data?.accessibility_tag_ids
      );
      return res;
    },
    { enabled: false }
  );
};

export default useMutateFetchOptions;
