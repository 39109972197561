import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LocationTag from './LocationTag';
import getIcon from '../../util/getIcon';
import { getLocationLink } from '../../util/getLocationLink';
import icon from '../../config/icons';
import Rating from '../generic/Rating';

import skeleton from '../../assets/skeleton.png';

const LocationListItem = ({
  id,
  municipality,
  name,
  image,
  accessibilityTags,
  locationTypeName,
  locationType,
  locationTypeLabelSingular,
  setFavorite,
  isFavorite,
  rating,
  totalReviews,
}) => {
  const [highlightTag, setHighlightTag] = useState(null);
  const [isImageLoading, setImageLoading] = useState(true);
  const MAX_ICONS = name.length >= 14 ? 3 : 4;

  return (
    <div className="size-full p-2 animate-fadeIn" tabIndex={0}>
      <div className="overflow-hidden rounded-lg relative">
        <div className="aspect-square group">
          <Link
            to={getLocationLink(municipality, locationType, name, id)}
            className="size-full"
          >
            <img
              alt={`Huvudbild för ${name}`}
              className="hover:scale-[1.05] hover:opacity-90 duration-300 object-cover size-full transition-all ease-in-out"
              src={isImageLoading ? skeleton : image}
              onLoad={() => setImageLoading(false)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src =
                  'https://png.pngtree.com/png-clipart/20190619/original/pngtree-vector-picture-icon-png-image_4013511.jpg';
              }}
            />
          </Link>
          {setFavorite && (
            <button
              aria-label={`Lägg till ${name} som favorit`}
              className={`absolute top-3 right-3 rounded-full bg-secondary p-2
              ${isFavorite ? 'opacity-100' : 'md:opacity-0 md:group-hover:opacity-100'}
            transition-all hover:scale-[1.1] z-[1]`}
              onClick={setFavorite}
            >
              {isFavorite
                ? getIcon(icon.HEART_FILLED, 'text-lg')
                : getIcon(icon.HEART, 'text-lg')}
            </button>
          )}
          {totalReviews?.length > 0 && (
            <div className="absolute bottom-3 right-3 z-[1]">
              <Rating rating={rating} totalReviews={totalReviews} />
            </div>
          )}
          {locationTypeLabelSingular && !highlightTag && (
            <div className="rounded-full shadow-md bg-secondary px-3.5 py-0.5 font-medium text-md absolute top-3 left-3 z-[1]">
              {locationTypeLabelSingular}
            </div>
          )}
          <div
            className={`absolute bottom-0 w-full bg-secondary/90 flex
              ease-out
               ${highlightTag ? 'h-1/2 px-4 py-2' : 'h-0 overflow-hidden'} transition-all duration-500`}
          >
            {highlightTag && (
              <div className="flex flex-col">
                <label className="text-text2 text-sm mb-2">
                  Platsen är anpassad för
                </label>
                <label className="text-text1 text-lg font-medium">
                  {highlightTag?.label}
                </label>
                <p className="text-text1">{highlightTag?.description}</p>
              </div>
            )}
          </div>
        </div>
        <div className="h-full w-full absolute animate-image left-0 top-0 pointer-events-none" />
      </div>
      <div className="flex pt-2 relative">
        <div className="w-full flex flex-col space-y-1">
          <p className="text-base font-medium text-text1 leading-4 whitespace-nowrap">
            {name}
          </p>
          <h3 className="text-sm text-text2 leading-4">{municipality}</h3>
        </div>
        <div className="items-center flex absolute right-0 h-9">
          <ul className="flex space-x-2 h-full group" tabIndex={0}>
            {accessibilityTags
              ?.slice(
                0,
                accessibilityTags.length === MAX_ICONS
                  ? MAX_ICONS
                  : MAX_ICONS - 1
              )
              .map((t, idx) => (
                <LocationTag
                  key={idx}
                  name={t.name}
                  ariaLabel={t.label}
                  image={t.image}
                  onHover={() => setHighlightTag(t)}
                  onHoverExit={() => setHighlightTag(null)}
                  className="border hover:border-border border-transparent bg-transparent"
                  size="24"
                />
              ))}
            {accessibilityTags?.length > MAX_ICONS && (
              <LocationTag
                label={`+${accessibilityTags?.length - MAX_ICONS}`}
                ariaLabel={`${accessibilityTags?.length - MAX_ICONS} fler taggar`}
              />
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

LocationListItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  address: PropTypes.string,
  image: PropTypes.string,
  accessibilityTags: PropTypes.array,
  locationType: PropTypes.string,
  locationTypeLabelSingluar: PropTypes.string,
  setFavorite: PropTypes.func,
  isFavorite: PropTypes.bool,
  rating: PropTypes.number,
  totalReviews: PropTypes.number,
};

export default LocationListItem;
