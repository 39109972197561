import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getLocationLink } from '../../../../util/getLocationLink';
import CloseButton from '../../buttons/CloseButton';

const SearchRow = ({ location, onClick, onClear }) => {
  return (
    <div className="flex justify-between">
      <Link
        onClick={() => onClick(location)}
        className="py-2.5 text-sm flex space-x-1 items-center text-text2"
        to={getLocationLink(
          location.municipality,
          location.location_type_label,
          location.name,
          location.id
        )}
      >
        <span className="text-text1 font-medium">{location.name}</span>
        <span className="lowercase">
          {location.location_type_label_singular}
        </span>
        <span className="">i</span>
        <span className="">{location.municipality}</span>
      </Link>
      {onClear && <CloseButton onClick={() => onClear(location)} />}
    </div>
  );
};

SearchRow.propTypes = {
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default SearchRow;
