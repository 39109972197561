import React, { useState } from 'react';
import PropTypes from 'prop-types';
import getTagIcon from '../../util/getTagIcon';
import getIcon from '../../util/getIcon';
import tagType from '../../config/tagType';
import icon from '../../config/icons';

const LocationDetailsTagsList = ({ accessibilityTags }) => {
  const [isExpanded, setExpanded] = useState(false);
  const MAX_ITEMS = 6;

  const visibleTags = isExpanded
    ? accessibilityTags
    : accessibilityTags.slice(0, MAX_ITEMS);

  return (
    <div className="flex flex-col items-start">
      <h3 className="text-2xl text-text1 font-medium mb-4 pb-4">
        Denna plats är anpassad för
      </h3>
      <div className="flex gap-6 flex-col">
        {visibleTags?.map((item, idx) => (
          <div key={idx} className="flex space-x-6 items-start">
            <div className="mt-2">
              {getTagIcon(item.name, tagType.ACCESSIBILITY, '26')}
            </div>
            <div className="">
              <h3 className="text-text1 text-md font-medium">{item.label}</h3>
              <p className="text-text2 text-md">{item.description}</p>
            </div>
          </div>
        ))}
        {accessibilityTags.length > MAX_ITEMS && (
          <button
            className="flex items-center mt-2 cursor-pointer gap-2"
            onClick={() => setExpanded(!isExpanded)}
            aria-expanded={isExpanded}
          >
            {isExpanded ? 'Visa mindre' : 'Visa mer'}
            {getIcon(icon.ARROW, `${isExpanded ? '' : 'rotate-180'}`)}
          </button>
        )}
      </div>
    </div>
  );
};

LocationDetailsTagsList.propTypes = {
  accessibilityTags: PropTypes.array,
};

export default LocationDetailsTagsList;
