import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CloseButton from './buttons/CloseButton';
import closeOnOutsideClickFunc from '../../util/closeOnOutsideClickFunc';
import Overlay from './Overlay';

const ModalCard = ({ size, padding, children, isOpen, onClose }) => {
  const ref = useRef();
  const [isOverlay, setOverlay] = useState(false);

  const handleClose = () => {
    if (isOpen) {
      onClose();
      setOverlay(false);
      ref.current.blur();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    setOverlay(isOpen);
    if (isOpen) {
      ref.current?.focus();
    }
  }, [isOpen, setOverlay]);

  closeOnOutsideClickFunc(ref, handleClose);

  return (
    <>
      <Overlay isActive={isOverlay} />
      <div
        onKeyDown={handleKeyDown}
        tabIndex={-1}
        className={`
        ${size || 'md:h-4/5 w-4/5'}
        bg-secondary fixed 
        md:rounded-2xl md:border border-border
        dont-print-this
        ${padding || ' p-8 md:p-10'}
        transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-30 flex flex-col
        ${isOpen ? 'opacity-1 animate-popDown' : 'opacity-0 animate-popUp transition-all duration-500 pointer-events-none'}
      `}
        ref={ref}
      >
        {children}
        {isOpen && (
          <CloseButton
            buttonStyle="absolute top-6 right-6 z-20 text-[28px]"
            onClick={handleClose}
          />
        )}
      </div>
    </>
  );
};

ModalCard.propTypes = {
  size: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalCard;
