import { useMutation } from 'react-query';
import actions from '../api/locations';

const useMutateFetchLocation = (onSuccess) => {
  return useMutation(
    'useMutateFetchLocation',
    async (id) => {
      const data = await actions.getLocation(id);
      return data;
    },
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
      },
    }
  );
};

export default useMutateFetchLocation;
