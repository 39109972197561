import React from 'react';
import PropTypes from 'prop-types';
import useFetchRelatedLocations from '../../../hooks/useFetchRelatedLocations';
import LocationListItem from '../../Location/LocationListItem';
import { useRecoilValue } from 'recoil';
import { locationTypes } from '../../../state/filter';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode, Mousewheel, Navigation } from 'swiper/modules';
import ArrowButton from '../../generic/buttons/ArrowButton';

const LocationRecommendationList = ({ locationId }) => {
  const { isLoading, data } = useFetchRelatedLocations(locationId);
  const allLocationTypes = useRecoilValue(locationTypes);

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex w-full h-full mb-20 flex flex-col dont-print-this">
      <div className="flex justify-between mb-4 items-center mb-4 px-4 md:px-0">
        <h3 className="text-xl md:text-2xl text-text1 font-medium">
          Vi tror även att du gillar
        </h3>
        <div className="flex space-x-4">
          <ArrowButton className="-rotate-90 custom-prev-recommendations shadow-none border border-border" />
          <ArrowButton className="rotate-90 custom-next-recommendations shadow-none border border-border" />
        </div>
      </div>
      <div className="size-full">
        <Swiper
          slidesPerView={1}
          spaceBetween={5}
          loop={true}
          freeMode={true}
          navigation={{
            prevEl: '.custom-prev-recommendations',
            nextEl: '.custom-next-recommendations',
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              spaceBetween: 15,
              slidesPerView: 4,
            },
          }}
          modules={[FreeMode, Autoplay, Mousewheel, Navigation]}
          className="size-full"
        >
          {data?.map((location, idx) => (
            <SwiperSlide key={idx} className="">
              <LocationListItem
                key={location.id}
                id={location.id}
                name={location.name}
                address={location.address}
                municipality={location.municipality}
                rating={location.average_rating}
                totalReviews={location.total_reviews}
                locationType={
                  allLocationTypes?.find((e) => e.id === location.location_type)
                    ?.name
                }
                image={location.image}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

LocationRecommendationList.propTypes = {
  locationId: PropTypes.number.isRequired,
};

export default LocationRecommendationList;
