import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import propTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { activeDropdown } from '../../../../../state/filter';
import icon from '../../../../../assets/iconBeta.svg';
import MainFilter from './MainFilter';
import HeaderTitle from './HeaderTitle';
import UserWayToggle from '../../menu/UserWayToggle';

const MainHeader = ({
  isMinimized,
  setLocationIds,
  isHeader,
  onFilterFocus,
  clearFilter,
  isFiltered,
}) => {
  const [activeFilter, setActiveFilter] = useRecoilState(activeDropdown);
  const isDetailView = useLocation().pathname.includes('/platser/');

  return (
    <div
      className={`
        absolute relative w-full 
        hidden justify-between items-center
        top-1/2 left-1/2 transform  -translate-x-1/2
        bg-inherit
        px-sm md:px-md lg:px-lg
        transition-height duration-filter
        duration-header
        z-30
        ${isDetailView ? 'lg:w-[80vw] lg:max-w-singlePage md:flex' : 'sm:flex'}
        ${!isMinimized ? 'translate- y-[-40%]' : ''}
        ${isMinimized ? 'h-20' : 'h-[350px]'}
         `}
    >
      <Link
        className="z-30 hidden md:block absolute left-0 top-6 px-sm md:px-md lg:px-lg"
        to="/"
      >
        <img alt="logotyp" className="md:w-36 lg:w-48 xl:w-full" src={icon} />
      </Link>
      <div className="size-full flex justify-center items-center">
        {false && <HeaderTitle isMinimized={!isHeader} />}
        <MainFilter
          isMinimized={isMinimized}
          active={activeFilter}
          setActive={setActiveFilter}
          isHeader={isHeader}
          setLocationIds={setLocationIds}
          clearFilter={clearFilter}
          isFiltered={isFiltered}
          onFilterFocus={onFilterFocus}
        />
      </div>
      <div className="hidden md:block fixed top-6 right-0 px-sm md:px-md lg:px-lg">
        <UserWayToggle />
      </div>
    </div>
  );
};

MainHeader.propTypes = {
  isMinimized: propTypes.bool,
  setLocationIds: propTypes.func,
  isHeader: propTypes.bool,
  onFilterFocus: propTypes.func,
};

export default MainHeader;
