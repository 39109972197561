import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import { HelmetProvider } from 'react-helmet-async';
import AppRouter from './components/routes/AppRouter';

import './theme/index.css';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <AppRouter />
        </HelmetProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default App;
