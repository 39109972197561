import React from 'react';
import PropTypes from 'prop-types';
import disableBackgroundScroll from '../../util/disableBackgroundScroll';

const Overlay = ({ isActive }) => {
  disableBackgroundScroll(isActive);

  return (
    <div
      className={`invisible md:visible fixed top-0 right-0 w-screen h-screen z-20
            transition-all duration-500 ease-in-out dont-print-this
        ${isActive ? 'bg-primary/50' : 'bg-transparent pointer-events-none'}
        `}
    />
  );
};

Overlay.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default Overlay;
