import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { favoritesLocationIds } from '../../../state/location';
import { headerState } from '../../../state/filter';
import useFetchLocationTypes from '../../../hooks/useFetchLocationTypes';
import useFetchCounties from '../../../hooks/useFetchCounties.js';
import useFetchAccessibilityTags from '../../../hooks/useFetchAccessibilityTags';

import MainHeaderMobile from './middle/MainHeaderMobile';
import BottomHeader from './bottom/BottomHeader';
import MainHeader from './middle/desktop/MainHeader';
import useFetchPages from '../../../hooks/useFetchPages.js';
import { getSavedLocations } from '../../../util/savedLocations.js';

const Header = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const headerRef = useRef();
  const [isHeader, setIsHeader] = useRecoilState(headerState);
  const isLandingPage = pathname === '/';
  const view = query.get('view');
  const setFavorites = useSetRecoilState(favoritesLocationIds);
  const isFavorites = query.get('favorites') === 'true';
  const activeLocationType = Number(query.get('type'));
  const isFiltered =
    query.get('ids') !== null ||
    query.get('type') !== null ||
    query.get('municipality') !== null;

  const {} = useFetchCounties();
  const {} = useFetchAccessibilityTags();
  const {} = useFetchPages();
  const {} = useFetchLocationTypes(() => {
    setFavorites(getSavedLocations());
  });

  const updateFilter = (data) => {
    query.set('page', 1);

    if (data.isFavorites) {
      query.set('favorites', data.isFavorites);
      query.delete('type');
    } else {
      query.delete('favorites');
      if (data.locationType) {
        query.set('type', data.locationType);
        query.delete('ids');
      } else {
        query.delete('type');
        query.delete('ids');
      }
    }

    query.delete('municipality');

    if (data.locationIds?.length > 0) {
      query.set('ids', data.locationIds);
    }

    setIsHeader(false);
    navigate(`/?${query.toString()}`, {
      replace: true,
    });
  };

  const handleClearFilter = () => {
    query.delete('ids');
    query.delete('type');
    query.delete('favorites');
    query.delete('municipality');
    query.set('page', '1');
    navigate(`${pathname}?${query.toString()}`, { replace: true });
  };

  const setLocationType = (type) => {
    updateFilter({
      locationType: type,
    });
  };

  const setLocationIds = (ids) => {
    updateFilter({
      locationIds: ids,
    });
  };

  const handleSetFavorites = (ids) => {
    updateFilter({
      locationIds: ids,
      isFavorites: true,
    });
  };

  const onFocus = () => {
    if (isHeader) {
      return;
    }

    if (view !== 'map' && isLandingPage) {
      window.scrollTo(0, 0);
      setIsHeader(true);
    }
  };

  useEffect(() => {
    query.set('view', 'map');
    navigate(`${pathname}?${query.toString()}`, { replace: true });
  }, []);

  return (
    <header
      ref={headerRef}
      className={`w-full fixed z-10 bg-secondary border-b dont-print-this border-border shadow-sm
        ${isLandingPage ? '' : 'border-none shadow-none md:border-border'}
        `}
    >
      <MainHeader
        isMinimized={!isHeader}
        setLocationIds={setLocationIds}
        isHeader={isHeader}
        onFilterFocus={onFocus}
        clearFilter={handleClearFilter}
        isFiltered={isFiltered}
      />
      <MainHeaderMobile
        setHeader={setIsHeader}
        isHeader={isHeader}
        setLocationIds={setLocationIds}
        isVisible={isLandingPage}
      />
      <BottomHeader
        isMinimized={!isHeader}
        activeType={activeLocationType}
        setLocationType={setLocationType}
        isFavorites={isFavorites}
        setFavorites={handleSetFavorites}
        isVisible={isLandingPage}
      />
      <div
        className={`absolute h-screen w-screen
         bg-gradient-to-t from-100% from-black z-10
         transition-all duration-filter
         ease-[cubic-bezier(0.1,0.9,0.2,.1)]
         ${isHeader ? 'opacity-10' : 'opacity-0 pointer-events-none'}`}
        onClick={() => setIsHeader(false)}
      />
    </header>
  );
};

export default Header;
