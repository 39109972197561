import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getLocationLink } from '../../../../util/getLocationLink';

const SearchRowRecommended = ({ location, onClick }) => {
  return (
    <div className="flex justify-between">
      <Link
        onClick={() => onClick(location)}
        className="py-2.5 text-sm flex space-x-1 items-center text-text2"
        to={getLocationLink(
          location.municipality,
          location.location_type_label,
          location.name,
          location.id
        )}
      >
        <img
          src={location.image}
          className="size-12 rounded-lg"
          aria-label={location.name}
        />
        <div className="flex flex-col space-x-1 justify-center">
          <span className="text-text1 font-medium pl-1">{location.name}</span>
          <div className="space-x-1">
            <span>{location.location_type_label_singular}</span>
            <span>i</span>
            <span>{location.municipality}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

SearchRowRecommended.propTypes = {
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SearchRowRecommended;
