import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MobileSearchField from './MobileSearchField';
import MobileFilterModal from './MobileFilterModal';
import disableBackgroundScroll from '../../../../util/disableBackgroundScroll';

const MobileFilter = ({ setLocationIds }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  disableBackgroundScroll(isModalOpen);

  return (
    <>
      <div
        className={`${!isModalOpen ? 'opacity-100' : 'opacity-0'} transition-all duration-header
      flex justify-between space-x-2`}
      >
        <MobileSearchField onClick={() => setModalOpen(true)} />
      </div>
      <MobileFilterModal
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        setLocationIds={setLocationIds}
      />
    </>
  );
};

MobileFilter.propTypes = {
  setLocationIds: PropTypes.func.isRequired,
};

export default MobileFilter;
