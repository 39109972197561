import React from 'react';
import PropTypes from 'prop-types';

const LocationDetailsMapViewSwitcher = ({ isMap, setMap }) => {
  return (
    <div
      className="md:hidden dont-print-this
    fixed transform -translate-x-1/2 left-1/2 bottom-4 b order border-primary shadow-[3px_9px_27px_9px_rgba(0,0,0,0.1)]
    w-3/5 h-12 bg-secondary z-[15] rounded-full px-1
    "
    >
      <div className="relative w-full h-full z-[16] flex items-center">
        <div
          className={`transition-all ease-in-out absolute top-1 bottom-1 w-1/2 bg-primary transform
            rounded-full ${isMap ? 'translate-x-full' : 'translate-x-0'} -z-10`}
        ></div>
        <button
          className={`w-1/2 z-20 ${isMap ? 'text-primary' : 'text-secondary'} transition-all`}
          onClick={() => setMap(false)}
        >
          Info
        </button>
        <button
          className={`w-1/2 z-20 ${isMap ? 'text-secondary' : 'text-primary'} transition-all`}
          onClick={() => setMap(true)}
        >
          Karta
        </button>
      </div>
    </div>
  );
};

LocationDetailsMapViewSwitcher.propTypes = {
  isMap: PropTypes.bool.isRequired,
  setMap: PropTypes.func.isRequired,
};

export default LocationDetailsMapViewSwitcher;
