import { getLocalStorageItem, setLocalStorageItem } from './localStorageUtil';

const STORAGE_KEY = 'search_history';

export const getSavedSearches = () => {
  const saved = getLocalStorageItem(STORAGE_KEY);
  return saved ? JSON.parse(saved) : [];
};

export const saveSearch = (location) => {
  const saved = getSavedSearches();
  if (!saved.map((s) => s.id).includes(location.id)) {
    saved.push(location);
    setLocalStorageItem(STORAGE_KEY, JSON.stringify(saved));
  }
};

export const removeSearch = (location) => {
  let saved = getSavedSearches();
  saved = saved.filter((s) => s.id !== location.id);
  setLocalStorageItem(STORAGE_KEY, JSON.stringify(saved));
};
