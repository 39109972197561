import { useQuery } from 'react-query';
import actions from '../api/locations';

const useFetchRecommendedLocaitons = () => {
  return useQuery(
    'useFetchRecommendedLocations',
    async () => {
      const data = await actions.getRecommendedLocations();
      return data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

export default useFetchRecommendedLocaitons;
