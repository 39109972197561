import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import PAGE_SIZE from '../../../../config/pageSize';
import getTagIcon from '../../../../util/getTagIcon';
import tagType from '../../../../config/tagType';
import icon from '../../../../config/icons';

import MapLocationListItem from './MapLocationListItem';
import MapLocationListItemLoader from './MapLocationListItemLoader';
import SkeletonBox from '../../../generic/loading/SkeletonBox';
import IconButton from '../../../generic/buttons/IconButton';

const MapLocationList = ({
  locations,
  locationsQueryResult,
  onSelect,
  isLoading,
  locationTypes,
}) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const fetchCount = query.get('ids')?.split(',').length || PAGE_SIZE;

  const fetchNextPage = () => {
    const nextPage = Number(query.get('page') || 1) + 1;
    query.set('page', nextPage);
    navigate(`${location.pathname}?${query.toString()}`, { replace: true });
  };

  const handleLocationClick = (location) => {
    onSelect({
      id: location.id,
      coordinates: [...location?.coordinates].reverse(),
    });
  };

  return (
    <div className="size-full">
      {isLoading ? (
        <SkeletonBox className="w-48 h-7 rounded-3xl mb-4" />
      ) : (
        <h2 className="text-lg font-semibold mb-4">{`Visar ${locations.length} av ${locationsQueryResult?.count} resultat`}</h2>
      )}
      <ul className="space-y-4 overflow-y-auto h-full pb-16 bg-accent p-4 rounded-xl">
        {locations?.map((location, idx) => (
          <MapLocationListItem
            key={idx}
            id={location.id}
            image={location.image}
            name={location.name}
            municipality={location.municipality}
            address={location.address}
            onClick={() => handleLocationClick(location)}
            rating={location.average_rating}
            totalReviews={location.total_reviews}
            has360={location.has_360}
            locationTypeIcon={getTagIcon(
              locationTypes?.find((t) => t.id === location.location_type)?.name,
              tagType.LOCATION_TYPE,
              '20'
            )}
          />
        ))}
        {isLoading &&
          new Array(fetchCount)
            .fill({})
            .map((e, idx) => <MapLocationListItemLoader key={idx} />)}
        {locationsQueryResult?.next && (
          <IconButton
            buttonStyle="mx-auto"
            text="Läs in fler"
            icon={icon.PLUS}
            onClick={fetchNextPage}
          />
        )}
      </ul>
    </div>
  );
};

MapLocationList.propTypes = {
  locations: PropTypes.array.isRequired,
  locationsQueryResult: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default MapLocationList;
