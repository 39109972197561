import React from 'react';
import ReviewStars from './ReviewStars';
import { formatDistanceToNow } from 'date-fns';
import { sv } from 'date-fns/locale';

const ReviewCard = ({ name, timestamp, rating, description, title, image }) => {
  const getTimeAgoInSwedish = (timestamp) => {
    return formatDistanceToNow(new Date(timestamp), {
      addSuffix: true,
      locale: sv,
    });
  };

  return (
    <div className="review-card border px-6 py-4 rounded-xl bg-white">
      <div className="flex justify-between mb-3">
        <div className="flex flex-col">
          <div className="flex items-center space-x-4">
            <h6 className="text-lg text-text1 leading-6">{name}</h6>
            <ReviewStars rating={rating} />
          </div>
          <label className="text-xs text-text4">
            {getTimeAgoInSwedish(timestamp)}
          </label>
        </div>
      </div>
      <h5 className="text-md text-text2 font-medium mb-1">{title}</h5>
      <p className="mb-2 text-text3 text-sm">{description}</p>
      {image && (
        <div className="mb-2">
          <img src={image} alt="Review" className="w-full h-auto rounded-md" />
        </div>
      )}
    </div>
  );
};

export default ReviewCard;
