import client from './client';

const BASE_URL = 'reviews';

const postReview = async (reviewData) => {
  try {
    const res = await client.post(`${BASE_URL}/create/`, reviewData);
    return res.data;
  } catch (error) {
    throw new Error('Failed to post review. Please try again.');
  }
};

const actions = {
  postReview,
};

export default actions;
