import { atom } from 'recoil';

export const view = atom({
  key: 'view',
  default: 'map',
});

export const isEasyMode = atom({
  key: 'isEaseMode',
  default: false,
});
