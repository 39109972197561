import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import LandingPage from '../../pages/LandingPage';
import LocationDetailPage from '../../pages/LocationDetailPage';
import Header from '../generic/header/Header';
import GenericPage from '../../pages/GenericPage';
import CookiesModal from '../generic/CookiesModal';
import RWDDebugger from '../generic/RWDDebugger';
import Footer from '../generic/footer/Footer';

const AppRouter = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {process.env.REACT_APP_DEBUG && <RWDDebugger />}
      <Toaster />
      <Header />
      <CookiesModal />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/platser/:municipality/:type/:name/:id"
          element={<LocationDetailPage />}
        />
        <Route path="/sida/:pageSlug" element={<GenericPage />} />
        <Route path="*" element={<Navigate to="/sida/404" replace />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default AppRouter;
