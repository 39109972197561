import React from 'react';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import icon from '../../config/icons';
import { favoritesLocationIds } from '../../state/location';
import { removeSavedLocation, saveLocation } from '../../util/savedLocations';
import getIcon from '../../util/getIcon';
import IconButton from '../generic/buttons/IconButton';
import BackButton from '../generic/buttons/BackButton';

const LocationDetailsUpperSection = ({
  locationName,
  municipality,
  municipalityId,
  locationTypeLabel,
  locationTypeId,
  subHeader,
  locationId,
  handleShare,
}) => {
  const [favoriteLocations, setFavoriteLocations] =
    useRecoilState(favoritesLocationIds);
  const isSaved = favoriteLocations.includes(locationId);

  const handleFavorite = () => {
    const icon = isSaved ? '✖️' : '🖤️';
    const message = isSaved
      ? 'Borttagen från favoriter'
      : 'Tillagd i favoriter';

    if (isSaved) {
      removeSavedLocation(locationId);
      setFavoriteLocations(favoriteLocations.filter((id) => id !== locationId));
    } else {
      saveLocation(locationId);
      setFavoriteLocations([...favoriteLocations, locationId]);
    }

    toast(message, {
      icon: icon,
      position: 'top-center',
      duration: 1000,
      style: {
        color: '#1E1E1E',
        background: '#FEFEFE',
        border: '1px solid #1E1E1E',
        padding: '16px',
      },
    });
  };

  return (
    <div className="flex w-full justify-between mb-6 items-end">
      <BackButton buttonStyle="px-4 w-fit md:hidden lg:flex lg:absolute -left-28 md:top-4" />
      <div className="flex flex-col hidden md:block">
        <div className="flex capitalize space-x-1 items-center text-text3 text-sm">
          <Link
            className="hover:underline text-text2"
            to={`/?municipality=${municipalityId}`}
          >
            {municipality}
          </Link>
          <span>{getIcon(icon.ARROW, 'rotate-90 text-xs')}</span>
          <Link
            className="hover:underline text-text2"
            to={`/?type=${locationTypeId}`}
          >
            {locationTypeLabel}
          </Link>
          <span>{getIcon(icon.ARROW, 'rotate-90 text-xs')}</span>
          <span className="text-text1">{locationName}</span>
        </div>
        <h1 className="text-3xl font-medium mt-4">{subHeader}</h1>
      </div>
      <div className="flex gap-3">
        <IconButton
          text={isSaved ? 'Ta bort favorit' : 'Lägg till favorit'}
          buttonStyle="px-4"
          hideTextOnMobile={true}
          icon={isSaved ? icon.HEART_FILLED : icon.HEART}
          onClick={handleFavorite}
          isDark={false}
        />
        <IconButton
          text="Dela"
          buttonStyle="px-4"
          hideTextOnMobile={true}
          icon={icon.SHARE}
          onClick={handleShare}
          isDark={false}
        />
      </div>
    </div>
  );
};

LocationDetailsUpperSection.propTypes = {
  locationName: PropTypes.string.isRequired,
  municipality: PropTypes.string.isRequired,
  municipalityId: PropTypes.number,
  locationTypeLabel: PropTypes.string.isRequired,
  locationTypeId: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
  handleShare: PropTypes.func.isRequired,
};

export default LocationDetailsUpperSection;
