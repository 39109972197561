import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Map, Marker } from 'react-map-gl';
import icon from '../../config/icons';
import MapBarButton from '../Maps/MapBarButton';
import getIcon from '../../util/getIcon';
import SkeletonBox from '../generic/loading/SkeletonBox';

const LocationDetailsMap = ({ location, isActive }) => {
  const map = useRef();
  const API_KEY = process.env.REACT_APP_MAP_BOX_API_KEY;
  const MAP_ID = 'mapbox://styles/williamwith/clyioll05010801qp67ju3cg8';
  const [isLoading, setLoading] = useState(true);
  const initialMapState = {
    latitude: location.coordinates[0],
    longitude: location.coordinates[1],
    zoom: 18,
  };

  const handleZoom = (isZoom) => {
    if (isZoom) {
      map.current?.zoomIn();
    } else {
      map.current?.zoomOut();
    }
  };

  const goToCenter = () => {
    map.current?.flyTo({
      center: [initialMapState.longitude, initialMapState.latitude],
      zoom: initialMapState.zoom,
      speed: 3.5,
      curve: 2.5,
      essential: true,
    });
  };

  const handleOnLoad = () => {
    setLoading(false);
  };

  return (
    <Map
      ref={map}
      dragPan={isActive}
      dragRotate={isActive}
      scrollZoom={isActive}
      keyboard={isActive}
      doubleClickZoom={isActive}
      mapboxAccessToken={API_KEY}
      initialViewState={{ ...initialMapState }}
      mapStyle={MAP_ID}
      onLoad={handleOnLoad}
      maxBounds={[
        [9.0, 54.0],
        [28.0, 75.0],
      ]}
      className="absolute size-full"
    >
      {isLoading ? (
        <SkeletonBox className="size-full rounded-2xl z-10 absolute inset-0" />
      ) : (
        <>
          {isActive && (
            <div
              className="flex flex-col rounded-lg
      bg-secondary divide-y-2 overflow-hidden shadow-lg
      absolute top-4 right-4 z-5"
            >
              <MapBarButton
                onClick={() => handleZoom(true)}
                icon={getIcon(icon.PLUS)}
              />
              <MapBarButton
                onClick={() => handleZoom(false)}
                icon={getIcon(icon.MINUS)}
              />
              <MapBarButton
                onClick={goToCenter}
                icon={getIcon(icon.NAVIGATION)}
              />
            </div>
          )}
          <Marker
            longitude={location.coordinates[1]}
            latitude={location.coordinates[0]}
          />
        </>
      )}
    </Map>
  );
};

LocationDetailsMap.propTypes = {
  location: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default LocationDetailsMap;
