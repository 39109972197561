import React from 'react';
import MobileFilter from '../phone/MobileFilter';
import PropTypes from 'prop-types';

const MainHeaderMobile = ({
  isHeader,
  isVisible,
  setHeader,
  setLocationIds,
}) => {
  return (
    <div
      className={`sm:hidden px-sm bg-inherit
            transition-all duration-header overflow-hidden
            ${isVisible ? (isHeader ? 'max-h-[800px]' : 'max-h-[120px]') : 'max-h-[0px]'}
            `}
    >
      <MobileFilter
        isHeader={isHeader}
        setHeaderState={setHeader}
        setLocationIds={setLocationIds}
      />
    </div>
  );
};

MainHeaderMobile.propTypes = {
  isHeader: PropTypes.bool.isRequired,
  setHeader: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setLocationIds: PropTypes.func.isRequired,
};

export default MainHeaderMobile;
