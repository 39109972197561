import React from 'react';
import PropTypes from 'prop-types';
import getIcon from '../../../util/getIcon';
import icon from '../../../config/icons';

const ReviewStars = ({ rating }) => {
  if (rating === undefined || isNaN(rating)) {
    return null;
  }
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
  return (
    <div className="flex space-x-1">
      {Array(fullStars)
        .fill(getIcon(icon.STAR))
        .map((el, idx) => (
          <div key={idx}>{el}</div>
        ))}
      {hasHalfStar && (
        <div className="flex">
          <div className="w-[8px] overflow-hidden">{getIcon(icon.STAR)}</div>
          <div className="w-[8px] overflow-hidden text-hover scale-x-[-1]">
            {getIcon(icon.STAR)}
          </div>
        </div>
      )}
      {Array(emptyStars)
        .fill(getIcon(icon.STAR, 'text-hover'))
        .map((el, idx) => (
          <div key={idx}>{el}</div>
        ))}
    </div>
  );
};

ReviewStars.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default ReviewStars;
