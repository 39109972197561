import React, { useEffect, useState } from 'react';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../util/localStorageUtil';
import IconButton from './buttons/IconButton';
import { useNavigate } from 'react-router-dom';
import Overlay from './Overlay';

const CookiesModal = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [isOverlay, setOverlay] = useState(false);

  const handleVisible = (value) => {
    setIsVisible(value);
    setOverlay(value);
  };

  const handleReadMore = (value) => {
    handleVisible(false);
    navigate('/sida/cookiespolicy');
  };

  useEffect(() => {
    const consent = getLocalStorageItem('cookieConsent');
    if (!consent) {
      handleVisible(true);
    }
  }, []);

  const handleAccept = () => {
    setLocalStorageItem('cookieConsent', 'accepted');
    handleVisible(false);
  };

  const handleDecline = () => {
    setLocalStorageItem('cookieConsent', 'declined');
    handleVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Overlay isActive={isOverlay} />
      <div
        className="w-full transform -translate-x-1/2 left-1/2 bottom-0 fixed z-40 
      bg-white ext-center px-10 py-6 border-t border-primary space-y-2 py-4 pb-6"
      >
        <div className="h-full md:w-2/3 mx-auto space-y-2">
          <h3 className="font-semibold text-xl w-full">Cookies</h3>
          <p className="w-full text-sm pb-4">
            Vi använder cookies för att förbättra din användarupplevelse och för
            att säkerställa att webbplatsen fungerar som förväntat. Cookies
            hjälper oss att anpassa din upplevelse på vår webbplats genom att
            komma ihåg dina preferenser och inställningar, samt att förbättra
            funktionaliteten och prestandan. Genom att samla in data om hur du
            använder vår webbplats kan vi också identifiera och åtgärda
            eventuella problem, samt optimera vårt innehåll och våra tjänster
            för att bättre möta dina behov. Vi använder cookies för att
            analysera trafik, förstå användarbeteenden och ge dig en mer
            personlig upplevelse när du besöker vår webbplats.
          </p>
          <div className="flex flex-col md:flex-row w-full md:justify-end md:space-x-4 space-y-2 md:space-y-0">
            <IconButton text="Jag accepterar" onClick={handleAccept} />
            <IconButton
              text="Jag accepterar inte"
              isDark={false}
              onClick={handleDecline}
            />
            <IconButton
              text="Läs mer"
              isDark={false}
              onClick={handleReadMore}
            />
          </div>
        </div>
      </div>
    </>
  );
};

CookiesModal.propTypes = {};

export default CookiesModal;
