import React from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import icon from '../../config/icons';
import { getLocationLinkExternal } from '../../util/getLocationLink';
import IconButton from '../generic/buttons/IconButton';
import ModalCard from '../generic/ModalCard';
import IconLinkButton from '../generic/buttons/IconLinkButton';

const ShareModal = ({ isOpen, onClose, location }) => {
  const LOCATION_PATH = getLocationLinkExternal(
    location?.municipality,
    location?.location_type_label,
    location?.name,
    location?.id
  );

  const shareItems = [
    {
      title: 'Kopiera',
      icon: icon.COPY,
      onClick: () => {
        navigator.clipboard.writeText(LOCATION_PATH);
        toast('Länk kopierad', {
          icon: '✅',
          position: 'top-center',
          duration: 800,
          style: {
            color: '#1E1E1E',
            background: '#FEFEFE',
            border: '1px solid #1E1E1E',
            padding: '16px',
          },
        });
      },
    },
    {
      title: 'Skriv ut',
      icon: icon.PRINT,
      onClick: () => window.print(),
      enabled: true,
    },
    {
      title: 'Maila',
      icon: icon.EMAIL,
      onClick: () =>
        (window.location.href = `mailto:?subject=Dela platsen&body=Kolla in denna platsen: ${LOCATION_PATH}`),
      enabled: true,
    },
    {
      title: 'Facebook',
      icon: icon.FACEBOOK,
      link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(LOCATION_PATH)}`,
      enabled: true,
    },
    {
      title: 'X / Twitter',
      icon: icon.TWITTER,
      link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(LOCATION_PATH)}`,
      enabled: true,
    },
    {
      title: 'Messenger',
      icon: icon.MESSENGER,
      link: `https://www.facebook.com/dialog/send?link=${encodeURIComponent(LOCATION_PATH)}&app_id=YOUR_APP_ID&redirect_uri=${encodeURIComponent(LOCATION_PATH)}`,
      enabled: false,
    },
    {
      title: 'WhatsApp',
      icon: icon.WHATSAPP,
      link: `https://api.whatsapp.com/send?text=${encodeURIComponent(LOCATION_PATH)}`,
      enabled: true,
    },
  ];

  return (
    <ModalCard
      onClose={onClose}
      isOpen={isOpen}
      size="size-full md:h-fit md:w-fit"
    >
      <div className="my-auto space-y-20 md:space-y-2">
        <h2 className="text-2xl font-semibold">Dela platsen</h2>
        <div className="w-full flex space-x-2 mt-auto bg-accent rounded-2xl p-4">
          <img
            className="size-16 md:size-16 rounded-lg"
            src={location?.image}
            alt={location?.name}
          />
          <div className="px-2 flex flex-col justify-center">
            <h3 className="text-xl font-medium">{location?.name}</h3>
            <label className="text-sm">{location?.address}</label>
          </div>
        </div>
        <div className="flex flex-wrap w-full justify-between mt-auto py-4 md:max-w-[400px]">
          {shareItems
            .filter((item) => item.enabled)
            .map((item, idx) =>
              item.link ? (
                <IconLinkButton
                  buttonStyle="w-36 md:w-48 space-x-6 rounded-lg mb-4"
                  key={idx}
                  href={item.link}
                  text={item.title}
                  icon={item.icon}
                  isDark={false}
                  target="_blank"
                />
              ) : (
                <IconButton
                  buttonStyle="w-36 md:w-48 space-x-6 rounded-lg mb-4"
                  key={idx}
                  onClick={item.onClick}
                  text={item.title}
                  icon={item.icon}
                  isDark={false}
                />
              )
            )}
        </div>
      </div>
    </ModalCard>
  );
};

ShareModal.propTypes = {
  location: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default ShareModal;
