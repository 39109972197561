import React from 'react';
import PropTypes from 'prop-types';
import truncateText from '../../util/truncateText';
import { getLocationLink } from '../../util/getLocationLink';
import IconLinkButton from '../generic/buttons/IconLinkButton';
import CloseButton from '../generic/buttons/CloseButton';
import icon from '../../config/icons';

const MapSingleLocationMobile = ({ location, onClose }) => {
  return (
    <div className="w-full md:hidden absolute top-8 z-10 md:px-md px-sm">
      <div className="bg-secondary rounded-2xl size-full flex p-4 space-x-4 relative">
        <img
          className="rounded-2xl w-1/3 aspect-square"
          src={location.image}
          alt={location.name}
        />
        <div className="flex flex-col w-2/3">
          <h1 className="text-text1 font-medium text-lg sm:text-2xl">
            {location.name}
          </h1>
          <p className="text-text2 text-sm sm:hidden">
            {truncateText(location.description, 120)}
          </p>
          <p className="text-text2 text-sm hidden sm:block">
            {truncateText(location.description, 500)}
          </p>
          <CloseButton buttonStyle="absolute top-3 right-3" onClick={onClose} />
        </div>
      </div>
      <IconLinkButton
        text="Gå till plats"
        iconStyle="text-2xl rotate-90"
        buttonStyle="ml-auto w-fit text-md flex-row-reverse mt-2"
        icon={icon.ARROW}
        isDark={false}
        href={getLocationLink(
          location.municipality,
          location.location_type_label,
          location.name,
          location.id
        )}
      />
    </div>
  );
};

MapSingleLocationMobile.propTypes = {
  location: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MapSingleLocationMobile;
