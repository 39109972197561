import React from 'react';
import propTypes from 'prop-types';
import getIcon from '../../../../../util/getIcon';
import icon from '../../../../../config/icons';

const FilterItem = ({
  id,
  title,
  subTitle,
  minimizedValue,
  extraStyle,
  isMinimized,
  activeItem,
  setActive,
}) => {
  return (
    <button
      id={'filterItem' + id}
      className={`flex w-full items-center justify-center
     relative overflow-hidden px-8 text-left
     max-w-64 h-full min-h-10 group 
      ${extraStyle} rounded-filter
      ${isMinimized ? '' : 'min-w-52'}
      ${activeItem === id ? 'bg-secondary' : 'hover:bg-hover hover:rounded-filter'}
      `}
      onClick={setActive}
    >
      <div
        className={`${isMinimized ? 'opacity-100 fadedText w-full' : 'opacity-0 w-0'} text-sm font-semibold text-center`}
      >
        {minimizedValue}
      </div>
      <div
        className={`flex flex-col max-h-18
        ${isMinimized ? 'opacity-0 w-0 h-0' : 'opacity-100 w-full'}`}
      >
        <div className="flex items-center space-x-2">
          <h3 className="font-semibold text-base text-text1">{title}</h3>
          {getIcon(
            icon.ARROW,
            `sm:hidden md:block text-xl transition-transform duration-filter
             ${activeItem === id ? 'rotate-0' : 'rotate-180'}`
          )}
        </div>
        <div className="sm:hidden md:block text-text2">{subTitle}</div>
      </div>
    </button>
  );
};

FilterItem.propTypes = {
  id: propTypes.number.isRequired,
  title: propTypes.string.isRequired,
  subTitle: propTypes.any.isRequired,
  extraStyle: propTypes.string,
  activeItem: propTypes.number,
  isMinimized: propTypes.bool,
  onClear: propTypes.func,
};

export default FilterItem;
