import React from 'react';
import SkeletonBox from '../generic/loading/SkeletonBox';

const LocationListItemLoader = () => {
  return (
    <div
      className="size-full group
      transition-all ease-out p-2"
    >
      <SkeletonBox className="w-full aspect-square rounded-lg" />
      <div className="flex mt-2 h-9 justify-between">
        <div className="flex flex-col h-full space-y-2">
          <SkeletonBox className="w-28 h-5 rounded-3xl" />
          <SkeletonBox className="w-14 h-4 rounded-3xl" />
        </div>
        <div className="space-x-2 flex h-full">
          <SkeletonBox className="w-[38px] h-full rounded-lg" />
          <SkeletonBox className="w-[38px] h-full rounded-lg" />
          <SkeletonBox className="w-[38px] h-full rounded-lg" />
          <SkeletonBox className="w-[38px] h-full rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default LocationListItemLoader;
