const replaceWhiteSpace = (name) => {
  return name?.trim().replace(/\s+/g, '-');
};

export const getLocationLink = (municipality, locationType, name, id) => {
  const formattedName = replaceWhiteSpace(name);
  const formattedMunicipality = replaceWhiteSpace(municipality);
  const formattedLocationType = replaceWhiteSpace(locationType);
  return `/platser/${formattedMunicipality}/${formattedLocationType}/${formattedName}/${id}`.toLowerCase();
};

export const getLocationLinkExternal = (
  municipality,
  locationType,
  name,
  id
) => {
  const BASE_URL = process.env.REACT_APP_FRONTEND_URL;
  const formattedName = replaceWhiteSpace(name);
  const formattedMunicipality = replaceWhiteSpace(municipality);
  const formattedLocationType = replaceWhiteSpace(locationType);
  return `${BASE_URL}platser/${formattedMunicipality}/${formattedLocationType}/${formattedName}/${id}`.toLowerCase();
};
