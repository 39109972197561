import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../../../InputField';
import IconButton from '../../../buttons/IconButton';

const MobileFilterSearchWrapper = ({
  search,
  setSearch,
  children,
  searchPlaceholder,
  onSubmit,
}) => {
  return (
    <div className="size-full flex flex-col relative pb-16">
      <InputField
        placeholder={searchPlaceholder}
        className="border border-border mb-4"
        text={search}
        setText={setSearch}
      />
      <div className="max-h-full overflow-y-scroll">{children}</div>
      <div className="mt-auo fixed bottom-0 w-full">
        <IconButton
          text="Spara och fortsätt"
          buttonStyle="w-full mt-auto p-4 h-fit"
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

MobileFilterSearchWrapper.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default MobileFilterSearchWrapper;
