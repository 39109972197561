import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

const FooterSection = ({ title, items }) => {
  return (
    <section>
      <h2 className="text-2xl mb-5 font-medium">{title}</h2>
      <ul className="text-base">
        {items?.map((el, idx) => (
          <li key={idx}>
            <Link className="hover:underline" to={el.slug}>
              <label>{el.title}</label>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

FooterSection.propTypes = {
  title: propTypes.string,
  items: propTypes.array,
};

export default FooterSection;
