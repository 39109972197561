import React from 'react';
import propTypes from 'prop-types';

const SubmitButton = ({
  label,
  ariaLabel,
  onClick,
  isDisabled,
  className,
  icon,
}) => {
  return (
    <button
      disabled={isDisabled}
      aria-label={ariaLabel}
      className={`flex items-center bg-primary text-secondary
      py-2 shadow rounded-full size-10 justify-center relative
      transition-all 
        ${isDisabled ? 'opacity-50' : 'md:hover:scale-105'} ${className}`}
      onClick={onClick}
    >
      {label}
      <div className="absolute right-4">{icon}</div>
    </button>
  );
};

SubmitButton.propTypes = {
  label: propTypes.string,
  ariaLabel: propTypes.string,
  isDisabled: propTypes.bool,
  onClick: propTypes.func,
  className: propTypes.string,
  icon: propTypes.any,
};

export default SubmitButton;
