export const generateFilterTextArray = (items, ids, defaultText) => {
  const active = items
    .filter((item) => ids?.includes(item?.id))
    .map((item) => item.label || item.name);

  if (active.length === 0) return [defaultText];
  if (active.length === 1) return [active[0]];
  if (active.length === 2) return [active[0], active[1]];
  if (active.length === 3) return [active[0], active[1], active[2]];

  return [active[0], active[1], active[2], 'och fler'];
};

export default generateFilterTextArray;
