import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import actions from '../api/locations';
import { locationTypes } from '../state/filter';

const useFetchLocationTypes = (onSuccess) => {
  const setLocationTypes = useSetRecoilState(locationTypes);
  return useQuery(
    'useFetchLocationTypes',
    async () => {
      const data = await actions.getLocationTypes();
      setLocationTypes(data);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
      },
    }
  );
};

export default useFetchLocationTypes;
