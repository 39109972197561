import { useEffect } from 'react';

export default function useCloseOnOutsideClick(ref, setState, isDelay = false) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (!isDelay) {
          setState(false);
        } else {
          // Delay closing the modal by 250 milliseconds
          setTimeout(() => {
            setState(false);
          }, 250); // Delay should be a number, not a string
        }
      }
    }

    // Add the event listener on component mount
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setState, isDelay]); // Dependencies include setState and isDelay
}
